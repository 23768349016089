/* Created: by : Masoud date: 1399/07/19 */

<template>
  <component
    :is="tag"
    :type="tag === 'button' ? nativeType : ''"
    :disabled="disabled || loading"
    class="btn"
    :class="[
      mainClasses,
      btnStyle,
      { 'btn-block': block },
      { 'btn-icon': icon },
      { [`btn-${type}`]: type },
      { [`btn-${size}`]: size },
      { 'btn-simple': simple },
      { disabled: disabled },
    ]"
    :style="[minWidth !== '' ? { minWidth: minWidth } : {}]"
  >
    <slot name="loading">
      <i v-if="loading" class="icon-spin6 animate-spin text-xl"></i>
    </slot>
    <slot></slot>
  </component>
</template>
<script>
export default {
  name: 'base-button',
  props: {
    tag: {
      type: String,
      default: 'button',
      description: 'Button html tag',
    },
    icon: Boolean,
    block: Boolean,
    loading: Boolean,
    disabled: Boolean,
    type: {
      type: String,
      default: '',
      description: 'Button type (primary|secondary|danger etc)',
    },
    nativeType: {
      type: String,
      default: 'button',
      description: 'Button native type (e.g button, input etc)',
    },
    minWidth: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
      description: 'Button size (sm|lg)',
    },
    simple: {
      type: Boolean,
      description: 'Whether button is simple (outlined)',
    },
    link: {
      type: Boolean,
      description: 'Whether button is a link (no borders or background)',
    },
    mainClasses: {
      type: String,
      default: '',
      description: 'button extra classes like (rounded-full)',
    },
  },
  computed: {
    btnStyle() {
      if (this.simple) return 'btn-outlined';
      if (this.link) return 'btn-link';
      if (this.icon) return 'btn-icon';
      return 'btn-filled';
    },
  },
};
</script>
