<template>
  <div class="loading-view">
    <div class="scaling-squares-spinner">
      <div class="square"></div>
      <div class="square"></div>
      <div class="square"></div>
      <div class="square"></div>
    </div>
  </div>
</template>
