/*
Created:
by  : Masoud
date: 1399/07/17
 */

const state = {
  // در این فایل در قسمت رنگ ها تم هایی با رنگ های مختلف برای نو، سایدبار و ... تعریف شده اند و این متغییر tailwind.config.js
  // تعیین میکند که از کدام تم باید استفاده شود
  theme: 0,

  // تاییین باز یا بسته شدن منوی سایدبار
  showSidebar: false,
};

const mutations = {};

const actions = {};

export default {
  state,
  mutations,
  actions,
};
