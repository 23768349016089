/* Created: by : Masoud date: 1399/07/25 */

<template>
  <div :class="['relative', mainClasses]">
    <slot name="label"></slot>
    <label class="block relative">
      <template v-if="type !== 'number' && type !== 'password'">
        <textarea
          v-if="type === 'textarea'"
          v-model.trim="content"
          @input="handleInput"
          @blur="handleBlur"
          @focus="$emit('focus')"
          spellcheck="false"
          :rows="rows"
          :class="['input', {'input--error' : haveError }]"
          :placeholder="placeholder"
          :maxlength="inputCounter.enabled ? inputCounter.max : 1000"
        ></textarea>
        <input
          v-else
          v-model.trim="content"
          @input="handleInput"
          @blur="handleBlur"
          @focus="$emit('focus')"
          spellcheck="false"
          :type="type"
          :class="['input', {'input--error' : haveError }]"
          :placeholder="placeholder"
          :maxlength="inputCounter.enabled ? inputCounter.max : 1000"
        />
      </template>
      <template v-else>
        <input
          v-model="content"
          @input="handleInput"
          @blur="handleBlur"
          @focus="$emit('focus')"
          :type="type"
          :class="['input', {'input--error' : haveError }]"
          :placeholder="placeholder"
          :maxlength="inputCounter.enabled ? inputCounter.max : 1000"
        />
      </template>
      <span v-if="inputCounter.enabled" class="input-counter">
        {{ inputCounter.max - content.length }}
      </span>
      <span v-if="$slots.errorText" class="span-error mx-1 w-full">
        <slot name="errorText"></slot>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'base-input',
  data() {
    return {
      content: this.modelValue,
    };
  },
  props: {
    modelValue: {
      require: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    mainClasses: {
      type: String,
      default: 'block',
    },
    haveError: {
      type: Boolean,
      default: false,
    },
    inputCounter: {
      type: Object,
      default: () => ({ enabled: false, max: 50 }),
    },
    placeholder: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 4,
    },
  },
  emits: ['update:modelValue', 'blur', 'input', 'focus'],
  methods: {
    handleInput() {
      this.$emit('update:modelValue', this.content);
      this.$emit('input');
    },
    handleBlur(e) {
      this.$emit('blur', e);
    },
  },
};
</script>
