/* Created: by : Masoud date: 1399/08/17 */
<template>
  <div
    :class="['number-input', cotainerClasses, {'disable-borders': labelPosition === 'around'}]"
    :style="[disableMargins ? { margin: '0px !important' } : {}]"
  >
    <div class="flex">
      <div class="label label--start text-card-pale card-header-bg" v-if="labelPosition === 'around'">
        <base-button type="primary" class="count-btn count-btn--lg" @click.stop="add(step * -1)">
          <i class="icon-feather-minus"></i>
        </base-button>
        <i v-if="labelPosition !== 'around'" class="border-end"></i>
      </div>
      <input
        v-model.number="myNumber"
        @blur="numberBlur"
        :class="[
          'input',
          inputClasses,
          labelPosition === 'around' ? 'rounded-none' : 'rounded-none rounded-r'
        ]"
        :style="[labelPosition === 'around' ? { textAlign: 'center !important' } : {}]"
        type="tel"
      />
      <div v-if="labelType === 'label'" class="label label--end card-header-bg" style="padding: 0 .5rem !important">
        {{ label }}
      </div>
      <div class="label label--end card-header-bg" v-if="labelType === 'countBtns'">
        <template v-if="labelPosition === 'end'">
          <base-button type="primary" class="count-btn count-btn--lg" @click.stop="subtrac()">
            <i class="icon-feather-minus"></i>
          </base-button>
          <i class="w-3"></i>
        </template>
        <base-button type="primary" class="count-btn count-btn--lg" @click.stop="add()">
          <i class="icon-feather-plus"></i>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      myNumber: this.number,
    };
  },
  props: {
    number: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 60,
    },
    label: {
      type: String,
      default: 'minute',
    },
    labelType: {
      type: String,
      default: 'label',
    },
    step: {
      type: Number,
      default: 1,
    },
    cotainerClasses: {
      type: String,
      default: '',
    },
    inputClasses: {
      type: String,
      default: 'w-16',
    },
    disableMargins: {
      type: Boolean,
      default: false,
    },
    labelPosition: {
      type: String,
      default: 'end',
    },
    decimalPlaces: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update:number', 'updated'],
  watch: {
    myNumber(newVal, oldVal) {
      if (newVal > this.max) {
        this.myNumber = oldVal;
      }
      this.$emit('update:number', Number.isNaN(this.myNumber) ? this.min : Number(this.myNumber) );
      this.$emit('updated');
    },
  },
  methods: {
    numberBlur() {
      this.myNumber = Number(this.myNumber);
      if (Number.isNaN(this.myNumber)) {
        this.myNumber = this.min;
      } else if (this.myNumber < this.min) {
        this.myNumber = this.min;
      }
    },
    add() {
      let c = this.myNumber + this.step;
      if (this.decimalPlaces !== 0) c = +c.toFixed(this.decimalPlaces);
      if (c > this.max) c = this.max;
      this.myNumber = c;
    },
    subtrac() {
      let c = this.myNumber - this.step;
      if (this.decimalPlaces !== 0) c = +c.toFixed(this.decimalPlaces);
      if (c < this.min) c = this.min;
      this.myNumber = c;
    },
  },
};
</script>
