<template>
  <div class="collapsing-panel card-bg">
    <div class="header" @click="collapsed = !collapsed">
      <div class="flex-1-0-0 overflow-hidden">
        <span v-if="!$slots.header">{{ header }}</span>
        <slot v-else name="header"></slot>
        <span
          class="mx-2 badge-base text-pale border bg-transparent"
          v-if="badge.enabled"
        >
          {{ badge.value }}
        </span>
      </div>
      <i :class="['icon-feather-chevron-down-1 text-2xl', collapsed ? '' : 'rotate-180']"></i>
    </div>
    <transition-slide>
      <slot v-if="!collapsed" name="content"></slot>
    </transition-slide>
  </div>
</template>

<script>
import TransitionSlide from './TransitionSlide.vue';

export default {
  props: {
    header: {
      type: [String, Number],
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    badge: {
      type: Object,
      default: () => ({ enabled: false, value: 0 }),
    },
  },
  data() {
    return {
      collapsed: !this.expanded,
    };
  },
  emits: ['update:expanded'],
  watch: {
    collapsed() {
      this.$emit('update:expanded', !this.collapsed);
    },
  },
  components: {
    TransitionSlide,
  },
  methods: {
    close() {
      this.collapsed = true;
    },
  },
};
</script>
