/*
Created:
by  : Masoud
date: 1399/07/17
 */

import { createStore } from 'vuex';
import app from './modules/app';
import * as getters from './getters';
import * as mutations from './mutations';

export default createStore({
  state: {},
  getters,
  mutations,
  actions: {},
  modules: {
    app,
  },
});
