import { Promise } from 'core-js';

export default {
  data() {
    return {
      coins: {},
      newCoinOption: {
        name: 'ارز جدید',
        idIR: undefined,
        idUSDT: undefined,
        enabled: true,
        useFunction1: true,
        useFunction2: true,
        minDeltaPercentF1: 0.6,
        minDeltaPercentF2: 0.43,
        alarmEnabled: true,
        quickAlarmPercentF1: 0.89,
        quickAlarmPercentF2: 0.89,
      },
      showBotOption: false,
      showCharts: true,
      minDeltaPercentForShow: 0.09,
      minHeadlineTotal: 300000,
      btcSeparateTradingView: true,
      coinsSymbole: [],

      usdt: { symbole: 'USDTIRT', name: 'تتر', id: undefined },

      arzpaya_CB: {}, // آبجکت اصلی کوین ها و دارای اطلاعات مربوط به کوین ها

      USDTsellMax: 0,
      USDTsellMaxAmount: 0,
      USDTbuyMin: 0,
      USDTbuyMinAmount: 0,
    };
  },
  inject: ['$axios'],
  computed: {
    minHeadlineTotalUSDT() {
      return this.minHeadlineTotal * this.USDTsellMax;
    },
    isAllCoinsSelectedF1: {
      get() {
        let unselectedFound = false;
        Object.keys(this.coins).forEach((key) => {
          if (!this.coins[key].useFunction1) unselectedFound = true;
        });
        return !unselectedFound;
      },
      set(value) {
        Object.keys(this.coins).forEach((key) => {
          this.coins[key].useFunction1 = value;
        });
      },
    },
    isAllCoinsSelectedF2: {
      get() {
        let unselectedFound = false;
        Object.keys(this.coins).forEach((key) => {
          if (!this.coins[key].useFunction2) unselectedFound = true;
        });
        return !unselectedFound;
      },
      set(value) {
        Object.keys(this.coins).forEach((key) => {
          this.coins[key].useFunction2 = value;
        });
      },
    },
    avalableF1Coins() {
      const a = [];
      Object.keys(this.coins).forEach((key) => {
        if (this.coins[key].enabled && this.coins[key].useFunction1) a.push(key);
      });
      return a;
    },
    avalableF2Coins() {
      const a = [];
      Object.keys(this.coins).forEach((key) => {
        if (this.coins[key].enabled && this.coins[key].useFunction2) a.push(key);
      });
      return a;
    },
  },
  watch: {
    coins: {
      handler() {
        localStorage.setItem('arzpayaCoins', JSON.stringify(this.coins));
      },
      deep: true,
    },
    showCharts() {
      localStorage.setItem('showCharts', this.showCharts);
      if (this.showCharts) {
        setTimeout(() => {
          this.loadTradingview();
        }, 200);
      }
    },
    minDeltaPercentForShow() {
      localStorage.setItem('minDeltaPercentForShow', this.minDeltaPercentForShow);
    },
    minHeadlineTotal() {
      localStorage.setItem('minHeadlineTotal_arzpayacoinInfo', this.minHeadlineTotal);
    },
    btcSeparateTradingView() {
      localStorage.setItem('btcSeparateTradingView', this.btcSeparateTradingView);
      setTimeout(() => {
        this.loadTradingview();
      }, 200);
    },
  },
  created() {
    const cachedCoins = localStorage.getItem('arzpayaCoins');
    if (cachedCoins) {
      // eslint-disable-next-line radix
      this.coins = JSON.parse(cachedCoins);
    } else {
      localStorage.setItem('arzpayaCoins', JSON.stringify(this.coins));
    }
    this.coinsSymbole = Object.keys(this.coins);
    // لود حداکثر زمان انتظار برای فروش
    const cachedWaitForSell = localStorage.getItem('waitForSell');
    if (cachedWaitForSell) {
      // eslint-disable-next-line radix
      this.waitForSell = parseInt(cachedWaitForSell);
    } else {
      localStorage.setItem('waitForSell', this.waitForSell);
    }
    // لود حداکثر زمان انتظار بعد از فروش
    const cachedWaitAfterSell = localStorage.getItem('waitAfterSell');
    if (cachedWaitAfterSell) {
      // eslint-disable-next-line radix
      this.waitAfterSell = parseInt(cachedWaitAfterSell);
    } else {
      localStorage.setItem('waitAfterSell', this.waitAfterSell);
    }
    // لود حداکثر زمان انتظار برای خرید
    const cachedWaitForBuy = localStorage.getItem('waitForBuy');
    if (cachedWaitForBuy) {
      // eslint-disable-next-line radix
      this.waitForBuy = parseInt(cachedWaitForBuy);
    } else {
      localStorage.setItem('waitForBuy', this.waitForBuy);
    }
    // لود فعال یا غیر فعال بودن تصحیح خرید بیش از موجودی
    const cachedOverValueOrderFixer = localStorage.getItem('overValueOrderFixer');
    if (cachedOverValueOrderFixer) {
      // eslint-disable-next-line radix
      this.overValueOrderFixer = cachedOverValueOrderFixer === 'true';
    } else {
      localStorage.setItem('overValueOrderFixer', this.overValueOrderFixer);
    }
    // لود فعال یا غیر فعال بودن نمایش نمودارها
    const cachedShowCharts = localStorage.getItem('showCharts');
    if (cachedShowCharts) {
      // eslint-disable-next-line radix
      this.showCharts = cachedShowCharts === 'true';
    } else {
      localStorage.setItem('showCharts', this.showCharts);
    }
    // لود حداقل سرخط برای در نظر گرفته شدن
    const cachedMinHeadlineTotal = localStorage.getItem('minHeadlineTotal_arzpayacoinInfo');
    if (cachedMinHeadlineTotal) {
      // eslint-disable-next-line radix
      this.minHeadlineTotal = Number(cachedMinHeadlineTotal);
    } else {
      localStorage.setItem('minHeadlineTotal_arzpayacoinInfo', this.minHeadlineTotal);
    }

    const cachedBtcSeparateTradingView = localStorage.getItem('btcSeparateTradingView');
    if (cachedBtcSeparateTradingView) {
      this.btcSeparateTradingView = cachedBtcSeparateTradingView === 'true';
    } else localStorage.setItem('btcSeparateTradingView', this.btcSeparateTradingView);
  },
  methods: {
    arzpaya_loadCoinsList() {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: 'GET',
          url: 'general/ExchangeType',
        })
          .then((responce) => {
            const data = responce.data.Data.Fa;
            for (let i = 0; i < data.length; i++) {
              if (data[i].Symbol !== 'USDTIR') {
                let symbole = data[i].Symbol;
                if (symbole.endsWith('IR')) {
                  symbole = symbole.substring(0, symbole.length - 2);
                } else symbole = symbole.substring(0, symbole.length - 4);
                let irSeted = false;
                let usdtSeted = false;
                if (this.coins[symbole]) {
                  if (this.coins[symbole].idIR) irSeted = true;
                  if (this.coins[symbole].idUSDT) usdtSeted = true;
                }
                if (!irSeted && !usdtSeted) {
                  this.coins[symbole] = JSON.parse(JSON.stringify(this.newCoinOption));
                  const title = data[i].Title;
                  this.coins[symbole].name = title.substring(0, title.indexOf('-'));
                  this.coinsSymbole.push(symbole);
                  this.arzpaya_CB[symbole] = {
                    buyMinIRT: 0,
                    buyMinAmountIRT: 0,
                    buyMinUSDT: 0,
                    buyMinAmountUSDT: 0,
                    sellMaxIRT: 0,
                    sellMaxAmountIRT: 0,
                    sellMaxUSDT: 0,
                    sellMaxAmountUSDT: 0,
                    positiveF1: false,
                    positiveF2: false,
                    firstHead: {
                      // فقط برای نمایش روی جدول برای محسبه اختلافات از تنظیمات کمک گرفته میشود و مقدار حداقل تنظیم میشود
                      buyMinIRT: 0,
                      buyMinAmountIRT: 0,
                      buyMinUSDT: 0,
                      buyMinAmountUSDT: 0,
                      sellMaxIRT: 0,
                      sellMaxAmountIRT: 0,
                      sellMaxUSDT: 0,
                      sellMaxAmountUSDT: 0,
                    },
                  };
                }
                if (!irSeted && data[i].Symbol.endsWith('IR')) {
                  this.coins[symbole].idIR = data[i].Id;
                }
                if (!usdtSeted && data[i].Symbol.endsWith('USDT')) {
                  this.coins[symbole].idUSDT = data[i].Id;
                }
              } else {
                this.usdt.id = data[i].Id;
              }
            }
            localStorage.setItem('arzpayaCoins', JSON.stringify(this.coins));
            resolve(true);
          })
          .catch((err) => {
            console.error(err);
            this.mainLoading = false;
            reject(err);
          });
      });
    },
  },
};
