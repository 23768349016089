<template>
  <!-- پاپ‌آپ تنظیمات -->
  <base-popup
    v-if="showBotOption"
    @close="showBotOption = false"
    title="تنظیمات"
    :useTranslation="false"
    maxWidth="32rem"
  >
    <div class="p-4">
      <div class="flex mt-2 mb-6">
        <base-checkbox
          type="primary"
          :value="btcSeparateTradingView"
          v-model="btcSeparateTradingView"
          disableInput
        >
          نمودار جداگانه برای بیت کوین
        </base-checkbox>
      </div>
      <!--تنظیمات حداقل درصد جهت نمایش  -->
      <div class="flex items-center mb-6">
        <span class="m-end-2">حداقل اختلاف نمایش: </span>
        <number-input
          v-model:number="minDeltaPercentForShow"
          disableMargins
          cotainerClasses="flex-1-0-0"
          inputClasses="flex-1-0-0"
          :min="-5"
          :max="5"
          :step="0.01"
          :decimalPlaces="2"
          labelType="countBtns"
        />
      </div>
      <div class="mb-4">
        <span class="block mb-1 text-card-pale">
          حداقل مجموع سرخط جهت محاسبه اختلاف ها:&nbsp;&nbsp;
          <strong class="text-card">{{ minHeadlineTotal }} ت</strong>
        </span>
        <number-input
          v-model:number="minHeadlineTotal"
          :min="300000"
          :max="100000000"
          :step="100000"
          disableMargins
          cotainerClasses="w-full"
          inputClasses="flex-1-0-0"
          labelType="countBtns"
        />
      </div>
      <!-- فعال یا غیرفعال کردن ارزها -->
      <h3>فعال یا غیر فعال کردن ارزها</h3>
      <div class="pr-4">
        <div class="mt-2 flex flex-wrap">
          <base-button
            v-for="ck in Object.keys(coins)"
            :key="ck"
            :type="coins[ck].enabled ? 'primary' : 'neural'"
            :simple="!coins[ck].enabled"
            @click="coins[ck].enabled = !coins[ck].enabled"
            class="m-end-2 mt-2"
            minWidth="5.4rem"
          >
            <span
              :class="['mt-1 mr-1 text-xs font-bold', { 'text-main-pale': !coins[ck].enabled }]"
            >
              {{ ck }}
            </span>
          </base-button>
        </div>
      </div>
      <h3 class="pt-4 mt-4 border-t border-primary">تنظیمات هشداردهنده ارزها</h3>
      <div class="flex pb-2 flex-wrap thin-scrollbar">
        <base-button
          v-for="ck in Object.keys(coins)"
          :key="ck"
          :type="ck === currentOptionCoin ? 'primary' : 'neural'"
          :simple="ck !== currentOptionCoin"
          @click="selectOptionCoin(ck)"
          class="m-end-2 mt-2"
          minWidth="5.4rem"
        >
          <span
            :class="['mt-1 mr-1 text-xs font-bold', { 'text-main-pale': ck !== currentOptionCoin }]"
          >
            {{ ck }}
          </span>
        </base-button>
      </div>
      <div class="mt-4 card-primary rounded p-1" v-if="!ressettingSetting">
        <h4 class="flex items-center">
          <span class="flex-grow">{{ coins[currentOptionCoin].name }}</span>
          <button link @click="copyCoinOptions(currentOptionCoin)">
            <i class="icon-feather-copy text-3xl"></i> کپی تنظیمات
            <span>{{ coins[currentOptionCoin].name }}</span>
          </button>
        </h4>
        <div class="bg-main text-main p-1 pb-4">
          <!-- فعال یا غیر فعال بودن هشدار دهنده  -->
          <div class="mt-4 flex">
            <base-checkbox
              type="primary"
              :value="coins[currentOptionCoin].alarmEnabled"
              v-model="coins[currentOptionCoin].alarmEnabled"
              disableInput
            >
              هشدار دهنده
            </base-checkbox>
          </div>
          <!-- فعال یا غیر فعال بودن خرید ریالی - فروش تتری -->
          <div class="mt-4 flex items-center">
            <div class="flex-none m-end-4">
              <base-checkbox
                type="primary"
                :value="coins[currentOptionCoin].useFunction1"
                v-model="coins[currentOptionCoin].useFunction1"
                disableInput
              >
                خرید ریالی - فروش تتری
              </base-checkbox>
            </div>
            <!-- حداقل درصد اختلاف مثبت خرید ریالی فروش تتری -->
            <div class="flex-1-0-0">
              <span class="block mb-1 text-card-pale">
                حداقل اختلاف مثبت:
                <strong class="text-card" dir="ltr"
                  >{{ coins[currentOptionCoin].minDeltaPercentF1 }}%</strong
                >
              </span>
              <number-input
                v-model:number="coins[currentOptionCoin].minDeltaPercentF1"
                disableMargins
                cotainerClasses="w-full"
                inputClasses="flex-1-0-0"
                :min="-1"
                :max="1000"
                :step="0.01"
                :decimalPlaces="2"
                labelType="countBtns"
              />
            </div>
          </div>
          <!-- فعال یا غیر فعال بودن خرید تتری - فروش ریالی  -->
          <div class="pb-4 flex items-center">
            <div class="m-end-4 flex-none">
              <base-checkbox
                type="primary"
                :value="coins[currentOptionCoin].useFunction2"
                v-model="coins[currentOptionCoin].useFunction2"
                disableInput
              >
                خرید تتری - فروش ریالی
              </base-checkbox>
            </div>
            <!-- حداقل درصد اختلاف مثبت خرید تتری فروش ریالی -->
            <div class="mt-4 flex-1-0-0">
              <span class="block mb-1 text-card-pale">
                حداقل اختلاف مثبت:
                <strong class="text-card" dir="ltr"
                  >{{ coins[currentOptionCoin].minDeltaPercentF2 }}%</strong
                >
              </span>
              <number-input
                v-model:number="coins[currentOptionCoin].minDeltaPercentF2"
                disableMargins
                cotainerClasses="w-full"
                inputClasses="flex-1-0-0"
                :min="-1"
                :max="1000"
                :step="0.01"
                :decimalPlaces="2"
                labelType="countBtns"
              />
            </div>
          </div>
          <!-- حداقل درصد خرید ریالی - فروش تتری برای هشدار سریع -->
          <p class="mt-4 mb-1 text-sm">حداقل درصد خرید ریالی - فروش تتری برای هشدار سریع</p>
          <number-input
            v-model:number="coins[currentOptionCoin].quickAlarmPercentF1"
            disableMargins
            cotainerClasses="w-full"
            inputClasses="flex-1-0-0"
            :min="0"
            :max="100"
            :step="0.01"
            :decimalPlaces="2"
            labelType="countBtns"
          />
          <!-- حداقل درصد خرید تتری - فروش ریالی برای هشدار سریع -->
          <p class="mt-4 mb-1 text-sm">حداقل درصد خرید تتری - فروش ریالی برای هشدار سریع</p>
          <number-input
            v-model:number="coins[currentOptionCoin].quickAlarmPercentF2"
            disableMargins
            cotainerClasses="w-full"
            inputClasses="flex-1-0-0"
            :min="0"
            :max="100"
            :step="0.01"
            :decimalPlaces="2"
            labelType="countBtns"
          />
        </div>
      </div>
    </div>
    <!-- پاپ آپ کپی تنظیمات کوین -->
    <base-popup
      v-if="isCopyingCoin"
      @close="isCopyingCoin = false"
      maxWidth="48rem"
      :title="'کپی تنظیمات ' + copyingCoin.name + ' برای ارزها'"
      :useTranslation="false"
    >
      <div class="p-4 sm:flex">
        <div class="flex-1-0-0 mb-8 sm:mb-0">
          <p class="mb-8 font-semibold">انتخاب تنظیمات:</p>
          <div v-for="item in copyOptions" :key="item.property" class="mb-4">
            <base-checkbox type="primary" :value="item.value" v-model="item.value" disableInput>
              {{ item.name }}
            </base-checkbox>
          </div>
        </div>
        <div class="w-4 hidden flex-none sm:block"></div>
        <div class="flex-1-0-0">
          <p class="mb-8 font-semibold">انتخاب کوین‌ها جهت کپی:</p>
          <div class="flex flex-wrap">
            <base-button
              v-for="ck in Object.keys(copyTargets)"
              :key="ck"
              :type="copyTargets[ck] ? 'primary' : 'neural'"
              minWidth="8rem"
              class="m-end-4 mb-3"
              @click="copyTargets[ck] = !copyTargets[ck]"
            >
              {{ coins[ck].name }}
            </base-button>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="p-4">
          <base-button type="primary" @click="doCopyCoin"> کپی کردن </base-button>
        </div>
      </template>
    </base-popup>
  </base-popup>
  <div class="home" v-if="!mainLoading">
    <div class="flex-1-0-0 overflow-y-auto thin-scrollbar relative">
      <div class="max-w-5xl mx-auto mt-2 xs:px-4">
        <div class="flex items-center px-4 my-4">
          <base-button type="primary" size="lg" icon @click="showBotOption = true">
            <i class="icon-feather-settings"></i>
          </base-button>
        </div>
        <!-- تتر -->
        <collapsing-panel header="تتر">
          <template #header>
            <div class="flex items-center">
              <span class="ml-8">تتر</span>
              <span class="text-success">{{ USDTbuyMin.toLocaleString() }}</span>
              <span class="py-1 px-4 rounded-full flex items-center card-info mx-2" dir="ltr">
                {{
                  Math.abs(USDTsellMax - USDTbuyMin) +
                  ' (' +
                  ((USDTbuyMin / USDTsellMax) * 100 - 100).toFixed(2) +
                  '%)'
                }}
              </span>
              <span class="text-danger">{{ USDTsellMax.toLocaleString() }}</span>
            </div>
          </template>
          <template #content>
            <table class="light-table">
              <thead>
                <tr>
                  <th class="border-l-2 border-b-2 card-neural">عنوان</th>
                  <th class="border-l-2 border-b-2 card-neural">P</th>
                  <th class="border-l-2 border-b-2 card-neural">V</th>
                  <th class="border-b-2 card-neural">S - 24</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border-b-2">خرید</td>
                  <td class="border-b-2">{{ USDTbuyMin.toLocaleString() }}</td>
                  <td class="border-b-2">{{ USDTbuyMinAmount.toLocaleString() }}</td>
                  <td class="border-b-2">
                    {{ parseInt(USDTbuyMin * USDTbuyMinAmount).toLocaleString() }}
                  </td>
                </tr>
                <tr>
                  <td>فروش</td>
                  <td>{{ USDTsellMax.toLocaleString() }}</td>
                  <td>{{ USDTsellMaxAmount.toLocaleString() }}</td>
                  <td>{{ parseInt(USDTsellMax * USDTsellMaxAmount).toLocaleString() }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </collapsing-panel>
        <!-- جدول -->
        <div class="flex-1-0-0">
          <collapsing-panel class="my-2">
            <template #header>
              <div class="flex items-center">
                <span class="ml-8">{{ coins[currentCoin].name }}</span>
                <span>{{ arzpaya_CB[currentCoin].firstHead.sellMaxUSDT.toLocaleString() }}</span>
              </div>
            </template>
            <template #content>
              <table class="light-table">
                <thead>
                  <tr>
                    <th class="border-l-2 border-b-4 card-neural">عنوان</th>
                    <th class="border-l-2 border-b-4 card-neural">P</th>
                    <th class="border-b-4 card-neural">S - 24</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :class="{
                      'bg-greenlight':
                        arzpaya_CB[currentCoin].firstHead.buyMinIRT <
                        arzpaya_CB[currentCoin].firstHead.buyMinUSDT * USDTbuyMin,
                    }"
                  >
                    <td class="border-b-2">خرید ریالی</td>
                    <td class="border-b-2">
                      {{ arzpaya_CB[currentCoin].firstHead.buyMinIRT.toLocaleString() }}
                    </td>
                    <td class="border-b-2">
                      {{
                        parseInt(arzpaya_CB[currentCoin].firstHead.buyMinAmountIRT).toLocaleString()
                      }}
                    </td>
                  </tr>
                  <tr
                    :class="{
                      'bg-greenlight':
                        arzpaya_CB[currentCoin].firstHead.buyMinIRT >
                        arzpaya_CB[currentCoin].firstHead.buyMinUSDT * USDTbuyMin,
                    }"
                  >
                    <td class="border-b-4">خرید تتری</td>
                    <td class="border-b-4">
                      <div>{{ arzpaya_CB[currentCoin].firstHead.buyMinUSDT.toLocaleString() }}</div>
                      <div class="mt-2px text-card-paler text-xs">
                        {{
                          parseInt(
                            arzpaya_CB[currentCoin].firstHead.buyMinUSDT * USDTsellMax,
                          ).toLocaleString()
                        }}
                      </div>
                    </td>
                    <td class="border-b-4">
                      <div>
                        {{ arzpaya_CB[currentCoin].firstHead.buyMinAmountUSDT.toLocaleString() }}
                      </div>
                      <div class="mt-2px text-card-paler text-xs">
                        {{
                          parseInt(
                            arzpaya_CB[currentCoin].firstHead.buyMinAmountUSDT * USDTsellMax,
                          ).toLocaleString()
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr
                    :class="{
                      'bg-greenlight':
                        arzpaya_CB[currentCoin].firstHead.sellMaxIRT >
                        arzpaya_CB[currentCoin].firstHead.sellMaxUSDT * USDTsellMax,
                    }"
                  >
                    <td class="border-b-2">فروش ریالی</td>
                    <td class="border-b-2">
                      {{ arzpaya_CB[currentCoin].firstHead.sellMaxIRT.toLocaleString() }}
                    </td>
                    <td class="border-b-2">
                      {{
                        parseInt(
                          arzpaya_CB[currentCoin].firstHead.sellMaxAmountIRT,
                        ).toLocaleString()
                      }}
                    </td>
                  </tr>
                  <tr
                    :class="{
                      'bg-greenlight':
                        arzpaya_CB[currentCoin].firstHead.sellMaxIRT <
                        arzpaya_CB[currentCoin].firstHead.sellMaxUSDT * USDTsellMax,
                    }"
                  >
                    <td>فروش تتری</td>
                    <td>
                      <div>
                        {{ Number(arzpaya_CB[currentCoin].firstHead.sellMaxUSDT).toLocaleString() }}
                      </div>
                      <div class="mt-2px text-card-paler text-xs">
                        {{
                          parseInt(
                            arzpaya_CB[currentCoin].firstHead.sellMaxUSDT * USDTsellMax,
                          ).toLocaleString()
                        }}
                      </div>
                    </td>
                    <td>
                      <div>
                        {{ arzpaya_CB[currentCoin].firstHead.sellMaxAmountUSDT.toLocaleString() }}
                      </div>
                      <div class="mt-2px text-card-paler text-xs">
                        {{
                          parseInt(
                            arzpaya_CB[currentCoin].firstHead.sellMaxAmountUSDT * USDTsellMax,
                          ).toLocaleString()
                        }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </collapsing-panel>
        </div>
        <!-- خرید و فروش‌های اختلاف سبز-->
        <div class="px-1 flex rounded border" style="min-height: 10rem">
          <!-- خرید ریالی و فروش تتری با اختلاف سبز -->
          <div class="flex-1-0-0 p-2 border-l">
            <span class="text-primary-color font-medium">خرید ریالی - فروش تتری</span>
            <template v-for="(key, i) in Object.keys(coins)" :key="i">
              <div
                v-if="
                  coins[key].enabled &&
                  coins[key].useFunction1 &&
                  coins[key].idIR &&
                  coins[key].idUSDT
                "
              >
                <div
                  v-if="f1[key].percent >= minDeltaPercentForShow && f1[key].percent < 100"
                  class="text-success font-medium flex pb-3 items-center"
                >
                  <span class="ml-1">{{ coins[key].name }}</span>
                  <div class="flex-1-0-0 flex justify-end px-2">
                    <span class="ml-2">{{ arzpaya_CB[key].buyMinIRT.toLocaleString() }}</span>
                    <span class="text-danger">{{
                      arzpaya_CB[key].sellMaxUSDT.toLocaleString() +
                      ' (' +
                      parseInt(arzpaya_CB[key].sellMaxUSDT * USDTsellMax).toLocaleString() +
                      ')'
                    }}</span>
                  </div>
                  <span
                    dir="ltr"
                    :class="[
                      f1[key].percent > coins[key].minDeltaPercentF1
                        ? 'card-success'
                        : 'card-danger',
                      'py-2 px-4 rounded-full font-bold',
                    ]"
                  >
                    {{ f1[key].percent + '%' }}
                  </span>
                </div>
              </div>
            </template>
          </div>
          <!-- خرید تتری و فروش ریالی با اختلاف سبز -->
          <div class="flex-1-0-0 p-2">
            <span class="text-primary-color font-medium">خرید تتری - فروش ریالی</span>
            <template v-for="(key, i) in Object.keys(coins)" :key="i">
              <div
                v-if="
                  coins[key].enabled &&
                  coins[key].useFunction2 &&
                  coins[key].idIR &&
                  coins[key].idUSDT
                "
              >
                <div
                  v-if="f2[key].percent >= minDeltaPercentForShow && f2[key].percent < 100"
                  class="text-success font-medium flex pb-3 items-center"
                >
                  <span class="ml-1">{{ coins[key].name }}</span>
                  <div class="flex-1-0-0 flex justify-end px-2">
                    <span class="ml-2">{{
                      arzpaya_CB[key].buyMinUSDT.toLocaleString() +
                      ' (' + (arzpaya_CB[key].buyMinUSDT * USDTsellMax).toLocaleString() +
                      ')'
                    }}</span>
                    <span class="text-danger">{{
                      parseInt(arzpaya_CB[key].sellMaxIRT).toLocaleString()
                    }}</span>
                  </div>
                  <span
                    dir="ltr"
                    :class="[
                      f2[key].percent > coins[key].minDeltaPercentF2
                        ? 'card-success'
                        : 'card-danger',
                      'py-2 px-4 rounded-full font-bold',
                    ]"
                  >
                    {{ f2[key].percent + '%' }}
                  </span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="flex flex-col lg:flex-row relative">
        <base-button
          type="primary"
          icon
          class="absolute right-0 top-0 mt-4 mr-4 z-10"
          size="lg"
          @click="changeChartOrder"
          v-if="btcSeparateTradingView"
        >
          <i class="icon-maximize- -rotate-45"></i>
        </base-button>
        <!-- Tradingview Selected Coin -->
        <div
          :class="['lg:flex-grow mt-4', chartOrder === 1 ? 'order-1' : 'order-2']"
          style="height: 350px"
        >
          <!-- TradingView Widget BEGIN -->
          <div id="tradingview_coin" class="h-full"></div>
          <!-- <div class="tradingview-widget-copyright">
            <a href="https://www.tradingview.com/symbols/BTCUSDT/?exchange=BINANCE" rel="noopener" target="_blank">
              <span class="blue-text">{{ currentCoin }}USDT Chart</span>
            </a> by TradingView
          </div> -->
          <!-- TradingView Widget END -->
        </div>
        <!-- Tradingview BTC -->
        <div
          v-if="btcSeparateTradingView"
          :class="['lg:flex-grow mt-4', chartOrder === 1 ? 'order-2' : 'order-1']"
          style="height: 350px"
        >
          <!-- TradingView Widget BEGIN -->
          <div id="tradingview_btc" class="h-full"></div>
          <!-- <div class="tradingview-widget-copyright">
            <a href="https://www.tradingview.com/symbols/BTCUSDT/?exchange=BINANCE" rel="noopener" target="_blank">
              <span class="blue-text">{{ currentCoin }}USDT Chart</span>
            </a> by TradingView
          </div> -->
          <!-- TradingView Widget END -->
        </div>
      </div>
    </div>
    <div class="border-t-2 border-primary pr-1 pb-1 flex flex-wrap">
      <template v-for="key in Object.keys(coins)" :key="key">
        <base-button
          v-if="coins[key].enabled"
          :type="key === currentCoin ? 'primary' : 'neural'"
          @click="selectCoin(key)"
          class="ml-2 mt-1 text-sm p1-important"
          minWidth="3rem"
        >
          {{ key }}
        </base-button>
      </template>
    </div>
  </div>
</template>

<script>
import CollapsingPanel from '@/components/CollapsingPanel.vue';
import NumberInput from '@/components/inputs/NumberInput.vue';
import optionsMixin from './_optionsMixin';

export default {
  data() {
    return {
      name: 'Unknown',
      td: undefined,
      currentCoin: 'BTC',
      lastNoteBtcSelectedCoin: 'ETH',

      mainWorker: undefined,
      usdtWorker: undefined,
      currentOptionCoin: 'BTC',
      ressettingSetting: false,
      copyingCoin: false,
      isCopyingCoin: undefined,
      copyTargets: {},
      copyOptions: [
        {
          property: 'useFunction1',
          name: 'خرید ریالی - فروش تتری (فعال یا غیر فعال)',
          value: false,
        },
        {
          property: 'minDeltaPercentF1',
          name: 'خرید ریالی - فروش تتری (حداقل اختلاف مثبت)',
          value: true,
        },
        {
          property: 'useFunction2',
          name: 'خری تتری - فروش ریالی (فعال یا غیر فعال)',
          value: false,
        },
        {
          property: 'minDeltaPercentF2',
          name: 'خرید تتری - فروش ریالی (حداقل اختلاف مثبت)',
          value: true,
        },
        { property: 'alarmEnabled', name: 'هشدار دهنده', value: true },
        {
          property: 'quickAlarmPercentF1',
          name: 'هشدار سریع برای خرید ریالی - فروش تتری',
          value: true,
        },
        {
          property: 'quickAlarmPercentF2',
          name: 'هشدار سریع برای خرید تتری - فروش ریالی',
          value: true,
        },
      ],
      alarmWorker: undefined,
      positiveCounterWorker: undefined,
      positiveCount: 0,
      alarmLock: false,
      checkAccessInterval: undefined,
      isMounted: false,
      chartOrder: 1,
      globalInterval: undefined,
      loadingIndex: 0,
    };
  },
  mixins: [optionsMixin],
  inject: ['onlineState', 'mainLoading', '$axios'],
  computed: {
    f1() {
      // مقدار سود و درصد سود خرید ریالی فروش تتری
      const f1 = {};
      Object.keys(this.coins).forEach((key) => {
        if (this.coins[key].enabled && this.coins[key].useFunction1) {
          const d =
            this.arzpaya_CB[key].sellMaxUSDT * this.USDTsellMax - this.arzpaya_CB[key].buyMinIRT;
          let p = ((d * 100) / this.arzpaya_CB[key].buyMinIRT).toFixed(2);
          p = Number(p);
          f1[key] = { delta: d, percent: p };
          if (p < 1000 && this.coins[key].alarmEnabled) {
            if (p >= this.coins[key].quickAlarmPercentF1) {
              this.doAlarm();
              this.arzpaya_CB[key].positiveF1 = true;
            } else if (p >= this.coins[key].minDeltaPercentF1) {
              this.arzpaya_CB[key].positiveF1 = true;
            } else this.arzpaya_CB[key].positiveF1 = false;
          }
        }
      });
      return f1;
    },
    f2() {
      // مقدار سود و درصد سود خرید تتری فروش ریالی
      const f2 = {};
      Object.keys(this.coins).forEach((key) => {
        if (this.coins[key].enabled && this.coins[key].useFunction2) {
          const d =
            this.arzpaya_CB[key].sellMaxIRT - this.arzpaya_CB[key].buyMinUSDT * this.USDTbuyMin;
          let p = ((d * 100) / (this.arzpaya_CB[key].buyMinUSDT * this.USDTbuyMin)).toFixed(2);
          p = Number(p);
          f2[key] = { delta: d, percent: p };
          if (p < 1000 && this.coins[key].alarmEnabled) {
            if (p >= this.coins[key].quickAlarmPercentF2) {
              this.doAlarm();
              this.arzpaya_CB[key].positiveF2 = true;
            } else if (p >= this.coins[key].minDeltaPercentF2) {
              this.arzpaya_CB[key].positiveF2 = true;
            } else this.arzpaya_CB[key].positiveF2 = false;
          }
        }
      });
      return f2;
    },
  },
  components: { NumberInput, CollapsingPanel },
  created() {
    const name = localStorage.getItem('loggedUserName');
    if (name) this.name = name;
    this.mainLoading = true;
    if (window.Worker) {
      Object.keys(this.coins).forEach((key) => {
        this.arzpaya_CB[key] = {
          buyMinIRT: 0,
          buyMinAmountIRT: 0,
          buyMinUSDT: 0,
          buyMinAmountUSDT: 0,
          sellMaxIRT: 0,
          sellMaxAmountIRT: 0,
          sellMaxUSDT: 0,
          sellMaxAmountUSDT: 0,
          positiveF1: false,
          positiveF2: false,
          binance: 0, // قیمت در بایننس
          firstHead: {
            // فقط برای نمایش روی جدول برای محسبه اختلافات از تنظیمات کمک گرفته میشود و مقدار حداقل تنظیم میشود
            buyMinIRT: 0,
            buyMinAmountIRT: 0,
            buyMinUSDT: 0,
            buyMinAmountUSDT: 0,
            sellMaxIRT: 0,
            sellMaxAmountIRT: 0,
            sellMaxUSDT: 0,
            sellMaxAmountUSDT: 0,
          },
        };
        this.copyTargets[key] = true;
      });
      this.$nextTick(() => {
        this.arzpaya_loadCoinsList()
          .then(() => {
            this.loadAll();
            this.mainLoading = false;
            this.mainWorker = new Worker('main-worker.js');
            // this.usdtWorker = new Worker('usdt-worker.js');
            this.mainWorker.onmessage = () => {
              if (this.onlineState.state) this.loadAll();
            };
            // this.usdtWorker.onmessage = () => {
            //   if (this.onlineState.state) this.loadUSDT();
            // };
          })
          .catch((err) => console.error(err));
      });
      this.alarmWorker = new Worker('alarm-worker.js');
      this.alarmWorker.onmessage = () => {
        if (this.positiveCount > 6) this.doAlarm();
        this.positiveCount = 0;
      };
      this.positiveCounterWorker = new Worker('positive-counter-worker.js');
      this.positiveCounterWorker.onmessage = () => {
        Object.keys(this.arzpaya_CB).forEach((key) => {
          if (this.arzpaya_CB[key].positiveF1 || this.arzpaya_CB[key].positiveF2)
            this.positiveCount++;
        });
      };
    } else {
      console.log("Your browser doesn't support web workers.");
      // eslint-disable-next-line no-alert
      alert("Your browser doesn't support web workers.");
      return;
    }
    const cachedMinDeltaPercentForShow = localStorage.getItem('minDeltaPercentForShow');
    if (cachedMinDeltaPercentForShow) {
      // eslint-disable-next-line radix
      this.minDeltaPercentForShow = Number(cachedMinDeltaPercentForShow);
    } else {
      localStorage.setItem('minDeltaPercentForShow', this.minDeltaPercentForShow);
    }
    this.checkAccessInterval = setInterval(() => {
      this.arzpaya_loadCoinsList();
      //   const token = window.localStorage.getItem('C_AUTH_KEY');
      //   if (token) {
      //     this.$axios2({
      //       method: 'GET',
      //       url: 'api/check-access',
      //     })
      //       .then((responce) => {
      //         if (responce.status === 401 || responce.data.status === 0) {
      //           window.localStorage.removeItem('C_AUTH_KEY');
      //           clearInterval(this.checkAccessInterval);
      //           this.$router.push({ name: 'Login' });
      //         }
      //       })
      //       .catch((error) => {
      //         if (error.response) {
      //           if (error.response.data.message === 'Unauthenticated') {
      //             window.localStorage.removeItem('C_AUTH_KEY');
      //             clearInterval(this.checkAccessInterval);
      //             this.$router.push({ name: 'Login' });
      //             return;
      //           }
      //         }
      //         if (error.request) {
      //           if (error.request.status) {
      //             if (error.request.status === 401) {
      //               window.localStorage.removeItem('C_AUTH_KEY');
      //               clearInterval(this.checkAccessInterval);
      //               this.$router.push({ name: 'Login' });
      //             }
      //           }
      //         }
      //       });
      //   } else {
      //     clearInterval(this.checkAccessInterval);
      //     this.$router.push({ name: 'Login' });
      //   }
    }, 26000);
  },
  mounted() {
    this.isMounted = true;
    // this.loadTradingview();
    this.pushkNotification();
  },
  methods: {
    changeChartOrder() {
      this.chartOrder = this.chartOrder === 1 ? 0 : 1;
    },
    async updateUSDT(data) {
      const USDTasks = data.asks;
      const USDTbids = data.bids;
      this.mainLoading = false;
      this.USDTsellMax = Number(USDTasks[0][0]);
      this.USDTsellMaxAmount = Number(USDTasks[0][1]);
      this.USDTbuyMin = Number(USDTbids[0][0]);
      this.USDTbuyMinAmount = Number(USDTbids[0][1]);
    },
    updateCoinData(data) {
      if (data.ExchangeType === this.usdt.id) {
        this.USDTbuyMin = data.CurrentBuyPrice;
        this.USDTbuyMinAmount = data.Volume;
        this.USDTsellMax = data.CurrentSellPrice;
        this.USDTsellMaxAmount = data.Volume;
        return;
      }
      for (let i = 0; i < this.coinsSymbole.length; i++) {
        const coin = this.coins[this.coinsSymbole[i]];
        if (coin.idIR === data.ExchangeType) {
          this.arzpaya_CB[this.coinsSymbole[i]].buyMinIRT = data.CurrentBuyPrice;
          this.arzpaya_CB[this.coinsSymbole[i]].firstHead.buyMinIRT = data.CurrentBuyPrice;
          this.arzpaya_CB[this.coinsSymbole[i]].sellMaxIRT = data.CurrentSellPrice;
          this.arzpaya_CB[this.coinsSymbole[i]].firstHead.sellMaxIRT = data.CurrentSellPrice;
          this.arzpaya_CB[this.coinsSymbole[i]].buyMinAmountIRT = data.Volume;
          this.arzpaya_CB[this.coinsSymbole[i]].firstHead.buyMinAmountIRT = data.Volume;
          this.arzpaya_CB[this.coinsSymbole[i]].sellMaxAmountIRT = data.Volume;
          this.arzpaya_CB[this.coinsSymbole[i]].firstHead.sellMaxAmountIRT = data.Volume;
          return;
        }
        if (coin.idUSDT === data.ExchangeType) {
          this.arzpaya_CB[this.coinsSymbole[i]].buyMinUSDT = data.CurrentBuyPrice;
          this.arzpaya_CB[this.coinsSymbole[i]].firstHead.buyMinUSDT = data.CurrentBuyPrice;
          this.arzpaya_CB[this.coinsSymbole[i]].sellMaxUSDT = data.CurrentSellPrice;
          this.arzpaya_CB[this.coinsSymbole[i]].firstHead.sellMaxUSDT = data.CurrentSellPrice;
          this.arzpaya_CB[this.coinsSymbole[i]].buyMinAmountUSDT = data.Volume;
          this.arzpaya_CB[this.coinsSymbole[i]].firstHead.buyMinAmountUSDT = data.Volume;
          this.arzpaya_CB[this.coinsSymbole[i]].sellMaxAmountUSDT = data.Volume;
          this.arzpaya_CB[this.coinsSymbole[i]].firstHead.sellMaxAmountUSDT = data.Volume;
          return;
        }
      }
    },
    loadUSDT() {
      // this.$axios({
      //   method: 'POST',
      //   url: 'general/orderbook',
      //   data: {
      //     ExchangeType: this.usdt.id,
      //     OrderType: 1,
      //     Count: 2,
      //   },
      // })
      //   .then((responce) => {
      //     const data = responce.data.Data;
      //     this.USDTbuyMin = data[0].Price;
      //     this.USDTbuyMinAmount = data[0].Amount;
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });
      this.$axios({
        method: 'POST',
        url: 'general/orderbook',
        data: {
          ExchangeType: this.usdt.id,
          OrderType: 2,
          Count: 2,
        },
      })
        .then((responce) => {
          const data = responce.data.Data;
          this.USDTsellMax = data[0].Price;
          this.USDTsellMaxAmount = data[0].Amount;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // loadCoin(symbole, ExchangeType, OrderType, isIR) {
    //   this.$axios({
    //     method: 'POST',
    //     url: 'general/orderbook',
    //     data: {
    //       ExchangeType,
    //       OrderType,
    //       Count: 10,
    //     },
    //   })
    //     .then((responce) => {
    //       // const data = responce.data.Data;
    //       // console.log(ExchangeType, symbole, OrderType, isIR, responce.data.Data);
    //       this.updateCoinData(symbole, OrderType, isIR, responce.data.Data);
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //       this.mainLoading = false;
    //     });
    // },
    loadAll() {
      this.$axios({
        method: 'GET',
        url: 'public/ExchangeInfo',
      })
        .then((responce) => {
          const data = responce.data.Data;
          // console.log(ExchangeType, symbole, OrderType, isIR, responce.data.Data);
          for (let i = 0; i < data.length; i++) {
            this.updateCoinData(data[i]);
          }
        })
        .catch((err) => {
          console.error(err);
          this.mainLoading = false;
        });
    },
    selectCoin(key) {
      if (this.btcSeparateTradingView) {
        if (key !== this.currentCoin) this.currentCoin = key;
        if (key !== 'BTC' && key !== this.lastNoteBtcSelectedCoin) {
          this.lastNoteBtcSelectedCoin = key;
          this.loadCoinTradingView();
        }
      } else if (key !== this.currentCoin) {
        this.currentCoin = key;
        this.loadCoinTradingView();
      }
    },
    selectOptionCoin(ck) {
      this.ressettingSetting = true;
      this.currentOptionCoin = ck;
      this.$nextTick(() => {
        this.ressettingSetting = false;
      });
    },
    copyCoinOptions(ck) {
      this.copyingCoin = this.coins[ck];
      this.isCopyingCoin = true;
    },
    doCopyCoin() {
      this.ressettingSetting = true;
      for (let i = 0; i < this.copyOptions.length; i++) {
        if (this.copyOptions[i].value) {
          Object.keys(this.coins).forEach((ck) => {
            if (this.coins[ck].name !== this.copyingCoin.name && this.copyTargets[ck]) {
              if (this.copyOptions[i].property === 'teter') {
                this.coins[ck].teterSell = JSON.parse(JSON.stringify(this.copyingCoin.teterSell));
                this.coins[ck].teterBuy = JSON.parse(JSON.stringify(this.copyingCoin.teterBuy));
              } else {
                this.coins[ck][this.copyOptions[i].property] =
                  this.copyingCoin[this.copyOptions[i].property];
              }
            }
          });
        }
      }
      this.$nextTick(() => {
        this.ressettingSetting = false;
        this.isCopyingCoin = false;
      });
    },
    loadCoinTradingView() {
      if (!this.isMounted) return;
      // eslint-disable-next-line max-len
      let symbol;
      if (!this.btcSeparateTradingView) {
        symbol =
          this.currentCoin === 'DAI' ? 'BINANCEUS:DAIUSD' : `BINANCE:${this.currentCoin}USDT`;
      } else {
        symbol =
          this.lastNoteBtcSelectedCoin === 'DAI'
            ? 'BINANCEUS:DAIUSD'
            : `BINANCE:${this.lastNoteBtcSelectedCoin}USDT`;
      }
      // eslint-disable-next-line no-new
      new TradingView.widget({
        autosize: true,
        symbol,
        interval: '5',
        timezone: 'Asia/Tehran',
        theme: 'dark',
        style: '1',
        locale: 'en',
        toolbar_bg: '#f1f3f6',
        enable_publishing: false,
        allow_symbol_change: false,
        // hide_top_toolbar: true,
        container_id: 'tradingview_coin',
      });
    },
    loadTradingview() {
      if (this.btcSeparateTradingView) {
        // eslint-disable-next-line no-new
        new TradingView.widget({
          autosize: true,
          symbol: 'BTCUSDT',
          interval: '5',
          timezone: 'Asia/Tehran',
          theme: 'dark',
          style: '1',
          locale: 'en',
          toolbar_bg: '#f1f3f6',
          enable_publishing: false,
          allow_symbol_change: false,
          // hide_top_toolbar: true,
          container_id: 'tradingview_btc',
        });
      }
      this.loadCoinTradingView();
    },
    doAlarm() {
      if (this.alarmLock) return;
      const audio = new Audio(`${process.env.BASE_URL}ring.mp3`);
      this.pushkNotification('موقعیت نوسان گیری - ارزپایا');
      audio.play();
      this.alarmLock = true;
      setTimeout(() => {
        this.alarmLock = false;
      }, 5000);
    },
    // logout() {
    //   const token = window.localStorage.getItem('C_AUTH_KEY');
    //   if (token) {
    //     this.$axios2({
    //       method: 'GET',
    //       url: 'api/logout',
    //     })
    //       .then((responce) => {
    //         if (responce.status === 401 || responce.data.status === 0) {
    //           window.localStorage.removeItem('C_AUTH_KEY');
    //           // clearInterval(this.checkAccessInterval);
    //           this.$router.push({ name: 'Login' });
    //         } else if (responce.data.status === 1) {
    //           window.localStorage.removeItem('C_AUTH_KEY');
    //           clearInterval(this.checkAccessInterval);
    //           this.$router.push({ name: 'Login' });
    //         }
    //       })
    //       .catch((error) => {
    //         if (error.response) {
    //           if (error.response.data.message === 'Unauthenticated') {
    //             window.localStorage.removeItem('C_AUTH_KEY');
    //             clearInterval(this.checkAccessInterval);
    //             this.$router.push({ name: 'Login' });
    //             return;
    //           }
    //         }
    //         if (error.request) {
    //           if (error.request.status) {
    //             if (error.request.status === 401) {
    //               window.localStorage.removeItem('C_AUTH_KEY');
    //               clearInterval(this.checkAccessInterval);
    //               this.$router.push({ name: 'Login' });
    //             }
    //           }
    //         }
    //       });
    //   } else {
    //     clearInterval(this.checkAccessInterval);
    //     this.$router.push({ name: 'Login' });
    //   }
    // },
    pushkNotification(msg = '') {
      // Let's check if the browser supports notifications
      if (!('Notification' in window)) {
        // eslint-disable-next-line no-alert
        alert('This browser does not support desktop notification');
      } else if (Notification.permission === 'granted') {
        // eslint-disable-next-line no-new
        if (msg !== '') new Notification(msg);
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted' && msg !== '') {
            // eslint-disable-next-line no-new
            new Notification(msg);
          }
        });
      }
    },
  },
  beforeUnmount() {
    this.mainWorker.terminate();
    this.alarmWorker.terminate();
    this.positiveCounterWorker.terminate();
    clearInterval(this.checkAccessInterval);
    clearInterval(this.globalInterval);
  },
};
</script>

