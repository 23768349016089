/* Created: by : Masoud date: 1399/07/18 */

<template>
  <div @click="checkBoxClick" class="form-check form-check-checkbox" :class="[{ disabled: disabled },type, inlineClass]">
    <label :for="cbId" class="form-check-label">
      <input
        v-if="!disableInput"
        :id="cbId"
        :class="['form-check-input', {'checked' : modelValue}, {'disabled': disabled}]"
        type="checkbox"
        :disabled="disabled"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <span v-else :class="['form-check-input', {'checked' : value}, {'disabled': disabled}]"></span>
      <span class="form-check-sign"></span>
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
    </label>
  </div>
</template>
<script>
export default {
  name: 'base-checkbox',
  props: {
    modelValue: {
      type: Boolean,
    },
    checked: {
      type: [Array, Boolean],
      description: 'Whether checkbox is checked',
    },
    type: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      description: 'Whether checkbox is disabled',
    },
    inline: {
      type: Boolean,
      description: 'Whether checkbox should be inline with other checkboxes',
    },
    disableInput: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
      description: 'if disableInput was true => value will used instead of modelValue',
    },
  },
  data() {
    return {
      cbId: '',
      touched: false,
    };
  },
  emits: ['update:modelValue'],
  computed: {
    inlineClass() {
      if (this.inline) {
        return 'form-check-inline';
      }
      return '';
    },
  },
  created() {
    this.cbId = Math.random()
      .toString(16)
      .slice(2);
  },
  methods: {
    checkBoxClick() {
      if (this.disableInput) {
        this.$emit('update:modelValue', !this.modelValue);
      }
    },
  },
};
</script>
