/*
Created:
by  : Masoud
date: 1399/07/17
 */

export const setTheme = (state, value) => {
  state.app.theme = value;
};
export const setShowSidebar = (state, value) => {
  state.app.showSidebar = value;
};
