<template>
  <app-layout />
</template>

<script>
import AppLayout from '@/layout/AppLayout.vue';

export default {
  components: { AppLayout },
};
</script>
