/*
Created:
by  : Masoud
date: 1399/07/16
 */

<template>
  <div>
    empty
  </div>
</template>

<script>
export default {
  name: 'notification',
};
</script>
