/* Created: by : Masoud date: 1399/08/02 */

<template>
  <div class="form-check form-check-radio" :class="[type, inlineClass, { disabled: disabled }]">
    <label :for="cbId" class="form-check-label">
      <input
        :id="cbId"
        :class="['form-check-input', {'checked' : model===value}, {'disabled': disabled}]"
        type="radio"
        :disabled="disabled"
        :name="name"
        :value="value"
        v-model="model"
      />
      <span class="form-check-sign"></span>
      <slot></slot>
    </label>
  </div>
</template>
<script>
export default {
  name: 'base-radio',
  props: {
    modelValue: {
      type: [String, Boolean, Number],
      description: 'Radio value',
    },
    type: {
      type: String,
      default: 'primary',
    },
    name: {
      type: [String, Number],
      description: 'Radio group name',
    },
    value: {
      description: 'Radio value',
    },
    disabled: {
      type: Boolean,
      description: 'Whether radio is disabled',
    },
    inline: {
      type: Boolean,
      description: 'Whether radio is inline',
    },
  },
  data() {
    return {
      cbId: '',
    };
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    inlineClass() {
      if (this.inline) {
        return 'form-check-inline';
      }
      return '';
    },
  },
  created() {
    this.cbId = Math.random()
      .toString(16)
      .slice(2);
  },
};
</script>
