/* Created: by : Masoud date: 1399/07/16 */

<template>
  <v-offline @detected-condition="amIOnline"></v-offline>
  <div class="app-layout bg-main">
    <transition name="fade" appear>
      <loading-view v-if="mainLoading" />
    </transition>
    <div class="app-layout--main text-main">
      <main class="nav-pages-container">
        <div class="p-4 card-danger" v-if="!onlineState.state">
          اتصال اینترتنی شما برقرار نمیباشد و داده ها بروز نمیشوند
        </div>
        <section class="pages-container">
          <router-view></router-view>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import LoadingView from '@/layout/LoadingView.vue';
import VOffline from '../plugins/VOffline.vue';

export default {
  name: 'app-layout',
  components: {
    LoadingView,
    VOffline,
  },
  inject: ['onlineState', 'mainLoading', '$axios'],
  created() {
    this.mainLoading = true;
  },
  methods: {
    amIOnline(e) {
      this.onlineState.state = e;
    },
  },
};
</script>
