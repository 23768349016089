import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import coinsInfo from './plugins/coinsInfo';
import store from './store';

const app = createApp(App);

app.use(coinsInfo);
app.use(store);

app.config.unwrapInjectedRef = true;

app.mount('#app');
