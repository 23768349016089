/*
Created:
by  : Masoud
date: 1399/07/14
 */

// import { BaseInput, Card, BaseDropdown, BaseButton, BaseCheckbox } from "../components/index";
import BaseButton from '@/components/BaseButton.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import BaseRadio from '@/components/BaseRadio.vue';
import BaseDropdown from '@/components/BaseDropdown.vue';
import Notification from '@/components/Notification.vue';
import BaseInput from '@/components/inputs/BaseInput.vue';
import Card from '@/components/cards/Card.vue';
import BasePopup from '@/components/BasePopup.vue';

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install: (app) => {
    app.component(Notification.name, Notification);
    app.component(BaseButton.name, BaseButton);
    app.component(BaseCheckbox.name, BaseCheckbox);
    app.component(BaseRadio.name, BaseRadio);
    app.component(BaseDropdown.name, BaseDropdown);
    app.component(BaseInput.name, BaseInput);
    app.component(BasePopup.name, BasePopup);
    app.component(Card.name, Card);
  },
};

export default GlobalComponents;
