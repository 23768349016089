/*
Created:
by  : Masoud
date: 1399/07/21
 */

/* eslint-disable import/extensions */
// eslint-disable-next-line import/no-unresolved
import clickOutside from '../directives/click-outside.js';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install: (app) => {
    app.directive('click-outside', clickOutside);
  },
};

export default GlobalDirectives;
