/* Created: by : Masoud date: 1399/07/21 */

<template>
  <component
    :is="tag"
    :class="['dropdown', { 'hide-sign': hideSign }, { show: isOpen }]"
    @click="toggleDropDown"
    v-click-outside="closeDropDown"
  >
    <i :class="['text-xl cursor-pointer', icon]"></i>
    <div
      class="dropdown-menu"
      :style="{ minWidth: minWidth }"
      :class="[{ show: isOpen }, dropdownClasses]"
    >
      <slot></slot>
    </div>
  </component>
</template>
<script>
export default {
  name: 'base-dropdown',
  props: {
    tag: {
      type: String,
      default: 'div',
      description: 'Dropdown html tag (e.g div, ul etc)',
    },
    icon: String,
    dropdownClasses: String,
    hideSign: {
      type: Boolean,
      default: true,
    },
    minWidth: {
      type: String,
      default: '150px',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;
      this.$emit('change', this.isOpen);
    },
    closeDropDown() {
      this.isOpen = false;
      this.$emit('change', false);
    },
  },
};
</script>
