/* eslint-disable no-unused-vars */
export default {
  beforeMount(el, binding, vnode, prevVnode) {
    el.clickOutsideEvent = (event) => {
      binding.value();
    };
    el.addEventListener('click', (event) => {
      event.stopPropagation();
    });
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
};
