/*
Created:
by  : Masoud
date: 1399/07/21
 */

/* eslint-disable import/extensions */
// eslint-disable-next-line import/no-unresolved

import { reactive, ref } from 'vue';
import axios from 'axios';
import router from '../router';

const onlineState = reactive({ state: true });

// Setting up Axios on Vue Instance, for use via this.$axios
const $axios = axios.create({
  baseURL: 'https://api.arzpaya.com/',
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

// Setting up Axios on Vue Instance, for use via this.$axios
// const $axios2 = axios.create({
//   baseURL: 'https://coin-info-backend.soomacompany.ir/',
//   // baseURL: 'http://127.0.0.1:8000/',
//   timeout: 15000,
//   headers: {
//     'Content-Type': 'application/json',
//     Accept: 'application/json',
//   },
// });

// router.beforeEach((to, from, next) => {
//   const token = window.localStorage.getItem('C_AUTH_KEY');
//   if (token) {
//     $axios2.defaults.headers.common.Authorization = token;
//     $axios2({
//       method: 'GET',
//       url: 'api/check-access',
//     })
//       .then((responce) => {
//         if (responce.status === 401 || responce.data.status === 0) {
//           window.localStorage.removeItem('C_AUTH_KEY');
//           next({ name: 'Login' });
//         } else if (to.name !== 'Home') {
//           next({ name: 'Home' });
//         } else next();
//       })
//       .catch((error) => {
//         if (error.response) {
//           if (error.response.data.message === 'Unauthenticated') {
//             window.localStorage.removeItem('C_AUTH_KEY');
//             next({ name: 'Login' });
//             return;
//           }
//         }
//         if (error.request) {
//           if (error.request.status) {
//             if (error.request.status === 401) {
//               window.localStorage.removeItem('C_AUTH_KEY');
//               next({ name: 'Login' });
//             }
//           }
//         }
//       });
//   } else if (to.name !== 'Login' && to.name !== 'Register') {
//     next({ name: 'Login' });
//   } else {
//     next();
//   }
// });

// $axios.interceptors.request.use(config => {
//   if (
//     config.method &&
//     (config.method.toLocaleLowerCase() === 'post' ||
//       config.method.toLocaleLowerCase() === 'put')
//   ) {
//     config.headers['content-type'] =
//       'application/x-www-form-urlencoded;charset=utf-8';
//     config.data = qs.stringify(config.data);
//   }

//   return config;
// });

const mainLoading = ref(false);

const globalProperties = {
  install: (app) => {
    app.provide('$axios', $axios);
    /// app.provide('$axios2', $axios2);
    app.provide('onlineState', onlineState);
    app.provide('mainLoading', mainLoading);
    app.use(router);
  },
};

export default globalProperties;
