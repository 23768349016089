import GlobalComponents from './globalComponents';
import GlobalDirectives from './globalDirectives';
import globalProperties from './globalProperties';

// css assets
import '@/assets/css/fontello.css';
import '@/scss/main.scss';

export default {
  install: (app) => {
    app.use(GlobalComponents);
    app.use(GlobalDirectives);
    app.use(globalProperties);
  },
};
