/* Created: by : Masoud date: 1399/07/21 */

<template>
  <div @click.stop="closePopup" :class="['base-popup', { 'bg-glass-dark': !closingPopup }]">
    <transition name="bounce" appear @after-leave="afterLeave">
      <card
        v-if="!closingPopup"
        :title="title"
        closeheader
        :useTranslation="useTranslation"
        :maxWidth="maxWidth"
        @close="closePopup"
      >
        <div class="base-popup--content">
          <slot></slot>
        </div>
        <template v-if="$slots.footer" #footer>
          <slot name="footer"></slot>
        </template>
      </card>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'base-popup',
  props: {
    title: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '60rem',
    },
    useTranslation: {
      type: Boolean,
      default: true,
      description: 'Use translation for title or not',
    },
  },
  data() {
    return {
      closingPopup: false,
    };
  },
  emits: ['close'],
  methods: {
    closePopup() {
      this.closingPopup = true;
    },
    afterLeave() {
      if (this.closingPopup) this.$emit('close');
    },
  },
};
</script>
