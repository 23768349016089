/* Created: by : Masoud date: 1399/07/21 */

<template>
  <div
    @click.stop="$emit('click')"
    class="card"
    :class="[type && `card-${type}`]"
    :style="[maxWidth ? { maxWidth: maxWidth } : {}]"
  >
    <div class="card-image" v-if="$slots.image">
      <slot name="image"></slot>
    </div>
    <div class="card-header" v-if="$slots.header" :class="headerClasses">
      <slot name="header">
        <strong class="card-title">{{ useTranslation ? $t(title) : title }}</strong>
      </slot>
    </div>
    <div class="card-header-close" v-if="closeheader" :class="headerClasses">
      <strong class="card-title">{{ useTranslation ? $t(title) : title }}</strong>
      <i class="flex-grow"></i>
      <base-button icon type="neural" @click="btnCloseClick">
        <i class="icon-feather-x"></i>
      </base-button>
    </div>
    <div class="card-body" :class="bodyClasses" v-if="$slots.default">
      <slot></slot>
    </div>
    <div class="card-footer" :class="footerClasses" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'card',
  data() {
    return {
      lock: false, // جلوگیری از مشکل 2 کلیک بر روی دکمه بستن
    };
  },
  props: {
    title: {
      type: String,
      description: 'Card title',
    },
    closeheader: {
      type: Boolean,
      default: false,
    },
    useTranslation: {
      type: Boolean,
      default: true,
      description: 'Use translation for title or not',
    },
    type: {
      type: String,
      description: 'Card type (e.g primary/danger etc)',
    },
    headerClasses: {
      type: [String, Object, Array],
      description: 'Card header css classes',
    },
    bodyClasses: {
      type: [String, Object, Array],
      description: 'Card body css classes',
    },
    footerClasses: {
      type: [String, Object, Array],
      description: 'Card footer css classes',
    },
    maxWidth: String,
  },
  emits: ['click', 'close'],
  methods: {
    btnCloseClick(evt) {
      this.$emit('close', evt);
    },
  },
};
</script>
<style></style>
